@font-face {
  font-family: "Nunito Sans";
  src: local("Nunito Sans"), url("../public/fonts/NunitoSans_10pt-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Nunito Sans";
  src: local("Nunito Sans"), url("../public/fonts/NunitoSans_10pt-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Nunito Sans";
  src: local("Nunito Sans"), url("../public/fonts/NunitoSans_10pt-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Nunito Sans";
  src: local("Nunito Sans"), url("../public/fonts/NunitoSans_10pt-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Nunito Sans";
  src: local("Nunito Sans"), url("../public/fonts/NunitoSans_10pt-Black.ttf") format("truetype");
  font-weight: 900;
}

.progress-bar {
  color: #ffff12;
}