.device {
  border-radius: 20px;
  position: relative;
  box-sizing: border-box;
  background: #fff;
  box-shadow: inset 0 4px 7px 1px #fff,
    inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14),
    0px 0px 44px 2px rgba(0, 0, 0, 0.75);
}

.device2 {
  border-radius: 20px;
  position: relative;
  box-sizing: border-box;
  background: #000;
  box-shadow: inset 0 4px 7px 1px #000,
    inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14),
    0px 0px 44px 2px rgba(0, 0, 0, 0.75);
}

.device img,
.device2 img {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.24);
}

.device::before,
.device::after {
  content: "";
  display: block;
  position: absolute;
  background: #fff;
  box-shadow: inset 0 4px 7px 1px #fff,
    inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14),
    0 10px 20px rgba(0, 21, 64, 0.05);
}

.device2::before,
.device2::after {
  content: "";
  display: block;
  position: absolute;
  background: #000;
  box-shadow: inset 0 4px 7px 1px #000,
    inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14),
    0 10px 20px rgba(0, 21, 64, 0.05);
}

.phone {
  padding: 12% 3%;
}

.phone2 {
  padding: 12% 3%;
}

// Phone button
.phone::after {
  border-radius: 50%;
  margin: 0 auto 2%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 10%;
  height: 0;
  padding-top: 10%;
  box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.12);
}

// Phone speaker
.phone::before {
  border-radius: 50px;
  margin: 5.5% auto 0;
  left: 0;
  right: 0;
  top: 0;
  width: 20%;
  height: 1%;
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.12);
}

// Phone button
.phone2::after {
  border-radius: 50%;
  margin: 0 auto 2%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 10%;
  height: 0;
  padding-top: 10%;
  box-shadow: inset 0 0 5px 1px silver;
}

// Phone speaker
.phone2::before {
  border-radius: 50px;
  margin: 5.5% auto 0;
  left: 0;
  right: 0;
  top: 0;
  width: 20%;
  height: 1%;
  box-shadow: inset 0 0 3px 1px #6e6e6e;
}

.device-collection {
  width: 80%;
  position: relative;
}

.device-collection::after {
  content: "";
  display: block;
  padding-top: 80%;
}

.device-collection .phone-container,
.device-collection .phone2-container {
  position: absolute;
  bottom: 0;
}

.device-collection .phone {
  margin: 0;
}

.device-collection .phone2 {
  margin: 0;
}

.device-collection .phone-container {
  left: 45%;
  top: 0;
  max-width: 40%;
  z-index: 2;
}

.device-collection .phone2-container {
  left: 12%;
  top: 10%;
  max-width: 35%;
  z-index: 2;
}

@media (max-width: 800px) {
  .device-collection .device,
  .device-collection .device2 {
    border-radius: 2vw;
  }
}

.container,
.device-collection {
  max-width: 100%;
  margin: 40px;
}
